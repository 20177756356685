import React from 'react';
import {
  createStyles,
  Box,
  Card,
  Stack,
  ThemeIcon,
  Text,
  Image,
} from '@mantine/core';

import rightAngle from '../assets/rightAngle.svg';

function AppProcess({ children, icon, title, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Card p={30} className={classes.card}>
        <Image src={rightAngle} width={30} className={classes.rightAngle} />
        <Stack spacing={40} align="center" py={40}>
          <ThemeIcon size={30} variant="transparency">
            {icon}
          </ThemeIcon>
          <Text weight={700} size="lg" color="dark">
            {title}
          </Text>
          <Text weight={400} size="sm">
            {children}
          </Text>
        </Stack>
      </Card>
    </Box>
  );
}

export default AppProcess;

const useStyles = createStyles({
  container: {
    position: 'relative',
  },
  card: {
    maxWidth: 330,
  },
  rightAngle: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});
