import React from 'react';
import { createStyles, Box, Text, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';

function TermsPlain({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <h4>
        <strong>I. INTRODUCTION</strong>
      </h4>

      <Text py={10} weight={400}>
        These Terms of Use and End User License Agreement (collectively, the
        &ldquo;Agreement&rdquo;) together with all the documents referred to in
        it constitute a legally binding agreement made between you as a natural
        person (&ldquo;you&rdquo;, &ldquo;your&rdquo; or &ldquo;user&rdquo;) and
        Trendest Insight SRL (&ldquo;we,&rdquo; &ldquo;us&rdquo; or
        &ldquo;our&rdquo;), concerning your access to and use of the Website,
        available at&nbsp;
        <Anchor component={Link} href="https://trendestinsight.com/">
          https://trendestinsight.com/
        </Anchor>
        &nbsp;(hereinafter, the &ldquo;Website&rdquo;).
      </Text>

      <Text py={10} weight={400}>
        All the documents that relate to the Website are hereby expressly
        incorporated herein by reference.
      </Text>

      <Text py={10} weight={400}>
        Please read this Agreement carefully before using the Website.
      </Text>

      <Text py={10} weight={400}>
        It is important that you read and understand this Agreement as by using
        the Website you indicate that you have read, understood, agreed and
        accepted the Agreement. By using the Website you agree to abide by this
        Agreement.
      </Text>

      <Text py={10} weight={400}>
        If you do not agree with (do not accept) this Agreement, or if you do
        not agree at least with one of the provisions of this Agreement, you are
        not authorized to, and you may not access and/or or use the Website and
        you must promptly discontinue accessing and/or using the Website.
      </Text>

      <h4>
        <strong>II. CHANGES TO THIS AGREEMENT</strong>
      </h4>

      <Text py={10} weight={400}>
        We reserve the right, at our sole discretion, to make changes or
        modifications to this Agreement at any time and for any reason. We will
        keep you informed about any changes by updating this Agreement and you
        waive any right to receive specific notice of each such change. It is
        your responsibility to periodically review this Agreement to stay
        informed of updates. You will be subject to, and will be deemed to be
        aware of and to have accepted, the changes in any revised Agreement by
        your continued use of the Website after the date such revised Agreement
        is posted.
      </Text>

      <h4>
        <strong>III. RESTRICTIONS ON WHO CAN USE THE WEBSITE</strong>
      </h4>

      <Text py={10} weight={400}>
        In order to access and/or use the Website, you must be eighteen (18)
        years of age or older.
      </Text>

      <Text py={10} weight={400}>
        All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by their parent or guardian to access and/or use the
        Website, so if you are between the ages of thirteen (13) and seventeen
        (17) years and you wish to access and/or use the Website, before doing
        so you must: (a) assure and confirm (if needed) that your parent or
        guardian have read and agreed (get your parent or guardian&rsquo;s
        consent) to this Agreement prior to your access and/or use the Website;
        (b) have the power to enter a binding contract with us and not be barred
        from doing so under any applicable laws.
      </Text>

      <Text py={10} weight={400}>
        Parents and guardians must directly supervise any access and/or use of
        the Website by minors.
      </Text>

      <Text py={10} weight={400}>
        <strong>
          Any person under the age of thirteen (13) years is not permitted to
          access and/or use the Website.
        </strong>
      </Text>

      <Text py={10} weight={400}>
        You affirm that you are either more than eighteen (18) years of age, or
        an emancipated minor, or possess legal parental or guardian consent, and
        are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations, and warranties set forth in
        this Agreement, and to abide by and comply with this Agreement.
      </Text>

      <h4>
        <strong>IV. GENERAL TERMS</strong>
      </h4>

      <Text py={10} weight={400}>
        The Website is developed for general information purposes and is
        intended only for your personal non-commercial use. You agree not to use
        the Website for any illegal, inappropriate or unauthorized purpose or
        activity.
      </Text>

      <h4>
        <strong>V. PRIVACY POLICY</strong>
      </h4>

      <Text py={10} weight={400}>
        Your privacy is very important to us. Accordingly, we have developed the
        Privacy Policy in order for you to understand how we process, use and
        store information including personal data. Access to and(/or) using the
        Website are subject to the Privacy Policy. By accessing and(/or) using
        the Website, you are deemed to have accepted the Privacy Policy, and in
        particular, you are deemed to have acknowledged the ways we process your
        information as well as appropriate legal grounds for processing
        described in the Privacy Policy. We reserve the right to amend the
        Privacy Policy from time to time. If you disagree with any part of the
        Privacy Policy, you must immediately stop accessing and(/or) using the
        Website. Please read our&nbsp;
        <Anchor component={Link} to="https://trendestinsight.com/privacy">
          Privacy Policy
        </Anchor>
        &nbsp;carefully.
      </Text>

      <h4>
        <strong>VI. END USER LICENSE AGREEMENT</strong>
      </h4>

      <Text py={10} weight={400}>
        By using the Website, you undertake to respect our intellectual rights
        (intellectual rights related to the Website&rsquo;s source code, graphic
        design, user interface, look and feel of the Website, content material,
        copyright and trademarks, hereinafter referred to as the
        &ldquo;Intellectual Property Rights&rdquo;) as well as those owned by
        third parties.
      </Text>

      <Text py={10} weight={400}>
        As long as you keep using the Website, we grant you a limited,
        non-exclusive, non-transferable, non-sublicensable, non-assignable and
        revocable right to access and use the Website pursuant to this Agreement
        (the &ldquo;License&rdquo;).
      </Text>

      <Text py={10} weight={400}>
        <strong>
          You may use our Website solely for your own non-commercial purposes.
          You are bound to respect the copyrighted material within the Website,
          you cannot sell pictures available on the Website.&nbsp;
        </strong>
      </Text>

      <Text py={10} weight={400}>
        The source code, design and content, including information, photographs,
        illustrations, artwork and other graphic materials, sounds, music or
        video (hereinafter &ndash; the &ldquo;works&rdquo;) as well as names,
        logos and trademarks (hereinafter &ndash; &ldquo;means of
        individualization&rdquo;) within the Website are protected by copyright
        laws and other relevant laws and/or international treaties, and belong
        to us and/or our partners and/or contracted third parties, as the case
        may be.
      </Text>

      <Text py={10} weight={400}>
        These works and means of individualization may not be copied,
        reproduced, retransmitted, distributed, disseminated, sold, published,
        broadcasted or circulated whether in whole or in part, unless expressly
        permitted by us and/or our partners and/or contracted third parties, as
        the case may be.
      </Text>

      <Text py={10} weight={400}>
        All rights, title and interest in and to the Website and its content,
        works and means of individualization as well as its functionalities (1)
        are the exclusive property of Trendest Insight SRL and/or our partners
        and/or contracted third parties, (2) are protected by the applicable
        international and national legal provisions, and (3) are under no
        circumstances transferred (assigned) to you in full or in part within
        the context of the license herewithin.
      </Text>

      <Text py={10} weight={400}>
        We will not hesitate to take legal action against any unauthorized use
        of our trademarks, names or symbols to protect and restore our rights.
        All rights not expressly granted herein are reserved. Other product and
        company names displayed on the Website or mentioned herein may also be
        the trademarks of their respective owners.
      </Text>

      <h4>
        <strong>VII. PROHIBITED BEHAVIOUR</strong>
      </h4>

      <Text py={10} weight={400}>
        You agree not to use the Website in any way that:
      </Text>

      <Text py={10} weight={400}>
        &ndash; &nbsp;&nbsp; is unlawful, illegal or unauthorized;
      </Text>

      <Text py={10} weight={400}>
        &ndash; &nbsp;&nbsp; is defamatory of any other person;
      </Text>

      <Text py={10} weight={400}>
        &ndash; &nbsp;&nbsp; is obscene or offensive;
      </Text>

      <Text py={10} weight={400}>
        &ndash; &nbsp;&nbsp; infringes any copyright, database right or
        trademark of any other person;
      </Text>

      <Text py={10} weight={400}>
        &ndash; &nbsp;&nbsp; advocates, promotes or assists any unlawful act
        such as (by way of example only) copyright infringement or computer
        misuse.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        You shall not modify, translate into other languages, reverse engineer,
        decompile, disassemble or otherwise create derivative works from the
        Website or any documentation concerning the Website.
      </Text>

      <Text py={10} weight={400}>
        You shall not transfer, lend, rent, lease, distribute the Website, or
        use it for providing services to a third party, or grant any rights to
        the Website or any documentation concerning the Website to a third
        party.
      </Text>

      <Text py={10} weight={400}>
        Misuse of any trademarks or any other content displayed on the Website
        is prohibited.
      </Text>

      <Text py={10} weight={400}>
        You shall not copy and/or duplicate and/or distribute and/or publish
        and/or use any content in the Website, directly or indirectly, by way of
        a violation of our Intellectual Property Rights.
      </Text>

      <Text py={10} weight={400}>
        Moreover, you shall not make any attempts to use the Website or part
        thereof for malicious intentions.
      </Text>

      <Text py={10} weight={400}>
        Also we are not responsible for the way you use the Website.
      </Text>

      <Text py={10} weight={400}>
        It is clarified that we may adopt, against a user who violated the
        present Agreement, any legal measures at our disposal pursuant to the
        applicable laws.
      </Text>

      <Text py={10} weight={400}>
        All disputes arising from the usage of the Website, shall be governed by
        and construed in accordance with the laws of the United States of
        America, and shall be submitted to the sole jurisdiction of the
        competent courts of New York, the United States of America.&nbsp;
      </Text>

      <h4>
        <strong>
          VIII. AVAILABILITY OF THE WEBSITE, SECURITY AND ACCURACY
        </strong>
      </h4>

      <Text py={10} weight={400}>
        In order to use the Website, you are required to have a compatible
        electronic device (computer, mobile phone or tablet) and Internet
        access.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        We do not warrant that the Website will be compatible with all hardware
        and software which you may use.
      </Text>

      <Text py={10} weight={400}>
        We make no warranty that your access to the Website will be
        uninterrupted, timely or error-free.
      </Text>

      <Text py={10} weight={400}>
        You acknowledge that the Website is provided via the Internet,
        therefore, the quality and availability of the Website may be affected
        by factors outside our reasonable control.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        We may add new features to the Website, change, update, upgrade, modify
        it or anything described in it without noticing you. If the need arises,
        we may suspend access to the Website, or close it indefinitely.
      </Text>

      <Text py={10} weight={400}>
        You also warrant that any information that you submit or send us via the
        Website is true, accurate and complete.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        If you decide not to use the Website for any reason you should stop
        accessing the Website.&nbsp;
      </Text>

      <h4>
        <strong>IX. CHARGES</strong>
      </h4>

      <Text py={10} weight={400}>
        Access to some services and/or additional features within the App
        requires paid subscriptions. The full list of Premium options and
        pricing is provided on the App&rsquo;s page on AppStore or Google Play.
        You will have an opportunity to try Premium options during the free
        trial period as provided on the signup screen. After the free trial
        period expires an auto-renewing subscription period will start on a
        regular basis.&nbsp;
        <strong>
          Please mind that you will be charged automatically unless you cancel
          your subscription 24 hours before the end of the free trial
          period.&nbsp;
        </strong>
        When you cancel your subscription you will still have access to basic
        functions of the App. Premium options are available during the whole
        free trial period. &nbsp;&nbsp;<strong>&nbsp;</strong>&nbsp;&nbsp;&nbsp;
      </Text>

      <Text py={10} weight={400}>
        <strong>
          Subscription with a free trial period will automatically renew to a
          paid subscription.&nbsp;
        </strong>
        Any unused portion of a free trial period, if offered, will be forfeited
        when the user purchases a subscription, where applicable. We reserve the
        right to modify, terminate or otherwise amend our offered subscription
        plans at any time.
      </Text>

      <Text py={10} weight={400}>
        <strong>
          Your subscription will be automatically renewed within 24 hours before
          the current subscription ends.
        </strong>
        &nbsp;Auto-renew option can be turned off in your&nbsp;
        <em>Apple ID account settings&nbsp;</em>/
        <em>&nbsp;Google Play account settings</em>&nbsp;at least 24 hours
        before the end of the current period. Payment will be charged to your
        Apple ID / Google Play Account at confirmation of purchase. No
        cancellation of the current subscription is allowed during active
        subscription period. Subscriptions are managed by you. Learn more about
        managing subscriptions (and how to cancel them) on&nbsp;
        <Anchor component={Link} to="https://support.apple.com/en-ca/HT202039">
          Apple support page
        </Anchor>
        &nbsp;/&nbsp;
        <Anchor
          component={Link}
          to="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&amp;hl=en">
          Google&rsquo;s support page
        </Anchor>
        . Please note that removing the App from your device does not deactivate
        your subscription.&nbsp;
      </Text>

      <h4>
        <strong>X. THIRD PARTY WEBSITES AND RESOURCES</strong>
      </h4>

      <Text py={10} weight={400}>
        The Website may link you to other sites on the Internet and contracted
        third parties to provide you certain services. We have no control over
        and accept no responsibility for the content of any website or mobile
        application to which a link from the Website exists (unless we are the
        provider of those linked websites or mobile applications). Such linked
        websites and mobile applications are provided &ldquo;as is&rdquo; for
        your convenience only with no warranty, express or implied, for the
        information provided within them.
      </Text>

      <Text py={10} weight={400}>
        You acknowledge sole responsibility for and assume all risk arising from
        your use of any third-party websites or resources.
      </Text>

      <Text py={10} weight={400}>
        If you have any queries, concerns or complaints about such third party
        websites or mobile applications (including, but not limited to, queries,
        concerns or complaints relating to products, orders for products, faulty
        products and refunds) you must direct them to the operator of that third
        party website or mobile application.
      </Text>

      <h4>
        <strong>XI. DISCLAIMER OF WARRANTIES</strong>
      </h4>

      <Text py={10} weight={400}>
        YOU AGREE THAT YOUR USE OF THE WEBSITE SHALL BE AT YOUR SOLE RISK. THE
        SERVICES AND ALL THE MATERIALS, INFORMATION, SOFTWARE, CONTENT
        INTEGRATED IN THE WEBSITE ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS
        AVAILABLE&rdquo;. WE DO NOT MAKE ANY WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED, WITH REGARD TO THE MERCHANTABILITY, TECHNICAL
        COMPATIBILITY OR FITNESS FOR A PARTICULAR PURPOSE OF ANY SERVICE,
        PRODUCT, CONTENT OR MATERIAL PROVIDED PURSUANT TO THIS AGREEMENT. WE DO
        NOT WARRANT THAT THE FUNCTIONS CONTAINED ON OR THROUGH THE WEBSITE OR
        ITS SERVICES WILL BE AVAILABLE, UNINTERRUPTED OR ERROR-FREE, THAT
        DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVERS THAT MAKE
        THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS.&nbsp;
      </Text>

      <h4>
        <strong>XII. LIMITATION OF LIABILITY</strong>
      </h4>

      <Text py={10} weight={400}>
        IN NO EVENT SHALL WE BE LIABLE FOR DAMAGES OF ANY TYPE, WHETHER DIRECT
        OR INDIRECT, ARISING OUT OF OR IN ANY WAY RELATED TO THE WEBSITE AND
        SERVICES PROVIDED BY THE WEBSITE. WE SHALL NOT BE LIABLE UNDER ANY
        CIRCUMSTANCES FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY OR
        PUNITIVE DAMAGES, OR LOSS OF PROFIT OR REVENUES, EVEN IF WE HAVE BEEN
        SPECIFICALLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WE SHALL NOT BE
        LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY
        RELATED TO PRODUCTS, SERVICES AND/OR INFORMATION OFFERED OR PROVIDED BY
        ANY THIRD-PARTIES AND ACCESSED THROUGH THE WEBSITE OR BY ANY OTHER
        MEANS. YOU ALSO SPECIFICALLY ACKNOWLEDGE THAT WE ARE NOT LIABLE FOR
        COSTS OR DAMAGES ARISING OUT OF PRIVATE OR GOVERNMENTAL LEGAL ACTIONS
        RELATED TO YOUR USE OF THE WEBSITE AND ITS SERVICES IN ANY COUNTRY.
      </Text>

      <h4>
        <strong>XIII. LEGAL COMPLIANCE</strong>
      </h4>

      <Text py={10} weight={400}>
        You must represent and warrant that (i) you are not located in a country
        that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a &ldquo;terrorist
        supporting&rdquo; country; and (ii) you are not listed on any U.S.
        Government list of prohibited or restricted parties.
      </Text>

      <h4>
        <strong>XIV. GOVERNING LAW AND CLAIMS</strong>
      </h4>

      <Text py={10} weight={400}>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of New York, USA.
      </Text>

      <Text py={10} weight={400}>
        We make no representations that the Website is appropriate or available
        for use in other locations. Those who access or use the Website from
        other jurisdictions do so at their own volition and are responsible for
        compliance with local law.
      </Text>

      <Text py={10} weight={400}>
        If you choose to access or use the Website from or in locations outside
        of the United States, you are responsible for:
      </Text>

      <Text py={10} weight={400}>
        a) ensuring that what you are doing in that country is legal; and
      </Text>

      <Text py={10} weight={400}>
        b) the consequences and compliance by you with all applicable laws,
        regulations, bylaws, codes of practice, licenses, registrations, permits
        and authorizations.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        Any claims shall be exclusively decided by courts of competent
        jurisdiction in New York, the State of New York, USA and applicable
        Federal law shall govern, without regard to choice of law principles.
      </Text>

      <Text py={10} weight={400}>
        If you ever wish to seek any relief from us, you agree to waive the
        ability to pursue class action.
      </Text>

      <Text py={10} weight={400}>
        If any controversy, allegation, or claim (including any non-contractual
        claim) arises out of or relates to the Website and the Services provided
        by the Website or this Agreement, then you and we agree to send a
        written notice to each other providing a reasonable description of the
        dispute, along with a proposed resolution of it. The notice shall be
        sent based on the most recent contact information. For a period of sixty
        (60) days from the date of receipt of notice from the other party, you
        and us will engage in a dialogue in order to attempt to resolve the
        dispute, though nothing will require either you or us to resolve the
        dispute on terms which either you or us, in each of our sole discretion,
        are uncomfortable with.
      </Text>

      <h4>
        <strong>XV. TERMINATION</strong>
      </h4>

      <Text py={10} weight={400}>
        We reserve the right to terminate this Agreement at any time at our sole
        discretion for any reason.
      </Text>

      <Text py={10} weight={400}>
        Upon any termination, (a) the rights and licenses granted to you herein
        shall terminate; (b) you must cease all access and/or use of the
        Website.&nbsp;
      </Text>

      <h4>
        <strong>XVI. SEVERABILITY</strong>
      </h4>

      <Text py={10} weight={400}>
        If at any time any provision of this Agreement is or becomes illegal,
        invalid or unenforceable in any respect, that provision shall be more
        narrowly construed so that it becomes legal, valid and enforceable or,
        if this is not possible, deleted. The other terms of this Agreement
        shall continue to apply with full force and effect.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        You shall not assign or transfer or purport to assign or transfer the
        contract between you and us to any other person.
      </Text>

      <h4>
        <strong>XVII. CONTACT INFORMATION</strong>
      </h4>

      <Text py={10} weight={400}>
        We reserve the right to respond to your requests, questions,
        commentaries or suggestions. For these purposes you can use the
        &ldquo;Contacts&rdquo; form available on the Website or reach us
        at&nbsp;
        <Anchor component={Link} to="mailto:support@trendestinsight.com">
          support@trendestinsight.com
        </Anchor>
        .
      </Text>
    </Box>
  );
}

export default TermsPlain;

const useStyles = createStyles({
  container: {},
});
