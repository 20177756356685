import React, { useState } from 'react';
import {
  createStyles,
  Box,
  Stack,
  Text,
  Anchor,
  Group,
  ThemeIcon,
  TextInput,
  Textarea,
  Button,
  Image,
  Center,
  Space,
  UnstyledButton,
  Paper,
  Notification,
  Loader,
  ActionIcon,
} from '@mantine/core';

import AppLogo from '../components/AppLogo';

// Images

// import partner1 from '../assets/partner1.webp';
import partner1 from '../assets/googleBanner.svg';
// import partner2 from '../assets/partner2.webp';
import partner2 from '../assets/fbBanner.svg';
// import partner3 from '../assets/partner3.webp';
import partner3 from '../assets/appleBanner.svg';
import partner10 from '../assets/admobBanner.svg';
import partner11 from '../assets/unityBanner.svg';
import partner4 from '../assets/partner4.webp';
import partner5 from '../assets/partner5.webp';

// Partners Logos
import lagoPartner1 from '../assets/partners/adjust-logo.png';
import lagoPartner2 from '../assets/partners/Amazon_logo.svg';
import lagoPartner3 from '../assets/partners/Data.ai.svg';
import lagoPartner4 from '../assets/partners/facebook-ads.svg';
import lagoPartner5 from '../assets/partners/Google_Ads_logo.svg';
import lagoPartner6 from '../assets/partners/LinkedIn_Logo.svg';
import lagoPartner7 from '../assets/partners/homepage_logo.png';
import lagoPartner8 from '../assets/partners/logo_black_medium.svg';
import lagoPartner9 from '../assets/partners/logotype.png';
import lagoPartner10 from '../assets/partners/Microsoft_logo.svg';
import lagoPartner11 from '../assets/partners/partner-logos-color-youtube-ads.svg';
import lagoPartner12 from '../assets/partners/Sensor-tower-logo-dark.svg';
import lagoPartner13 from '../assets/partners/TikTok_logo.svg';
import lagoPartner14 from '../assets/partners/twitter_ads_logo-1.svg';
import lagoPartner15 from '../assets/partners/Unity_Technologies_logo.svg';

import portfolio1 from '../assets/portfolio1.webp';
import portfolio2 from '../assets/portfolio2.webp';
import portfolio3 from '../assets/portfolio3.webp';
import faxSheetCover1 from '../assets/faxSheetCover1.webp';
import phoneProtectorCover1 from '../assets/phoneProtectorCover1.svg';

import portfolioIco1 from '../assets/callRecorderIco.svg';
import portfolioIco2 from '../assets/scannerPro.svg';
import portfolioIco3 from '../assets/cleanerIco.svg';
import portfolioIco4 from '../assets/callRecorderIco.svg';

import processBg from '../assets/processBg.svg';
import coverPhoto1 from '../assets/coverPhoto1.webp';
import coverApp1 from '../assets/coverApp1.webp';

import appFaxThatSheetLogo from '../assets/appFaxThatSheetLogo.svg';
import appStoreBadge from '../assets/AppStoreBadge.svg';

import { themeColors } from '../config/themeSettings';
import {
  IconBrandCitymapper,
  IconBrush,
  IconChartDots,
  IconCheck,
  IconArrowUpRight,
  IconMail,
  IconPhoneCall,
  IconTools,
  IconX,
  IconCircleArrowUp,
  IconArrowRight,
  IconArrowLeft,
  IconChevronRight,
  IconChevronLeft,
  IconMessage,
} from '@tabler/icons-react';
import { Carousel } from '@mantine/carousel';
import AppCard from '../components/AppCard';
import AppProcess from '../components/AppProcess';
import AppLink from '../components/AppLink';
import { useScrollIntoView, useWindowScroll } from '@mantine/hooks';
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { useFormspark } from '@formspark/use-formspark';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const FORMSPARK_FORM_ID = 'eoQ0jd1J';

let schema = yup.object({
  Name: yup.string().min(2).max(20).label('Name').required(),
  Email: yup.string().email().label('Email').required(),
  Subject: yup.string().min(2).label('Subject').required(),
  Message: yup.string().min(2).label('Message').required(),
});

function HomePage({ children, ...otherProps }) {
  const { classes } = useStyles();
  const { classes: inputArea } = useStylesInput();
  const { classes: stylesCarousel } = useStylesCarousel();

  const [currentSlideSize, setCurrentSlideSize] = useState(1);

  // Success Notification after sending email
  const [emailSuccess, setEmailSuccess] = useState(false);

  // Fail Notification after sending email
  const [emailError, setEmailError] = useState(false);

  // Loader Spinner when Email starts sending
  const [emailSending, setEmailSending] = useState(false);

  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    scrollIntoView,
    targetRef: ourProcessRef,
    scrollableRef,
  } = useScrollIntoView({
    offset: 60,
  });

  const [scroll, scrollTo] = useWindowScroll();

  const { scrollIntoView: portfolioView, targetRef: portfolioRef } =
    useScrollIntoView({
      offset: 60,
    });

  function scrollInto(idRef = '') {
    if (!idRef) return;
    document.getElementById(idRef)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  const onSubmit = data => {
    if (!data) return;

    const { Name, Email, Subject, Message } = data;
    const submitData = {
      From: Name,
      Email: Email,
      Message: Message,
      Subject: Subject,
    };

    setEmailSending(true);

    submit(submitData)
      .then(result => {
        setEmailSuccess(true);
        setEmailError(false);
        setEmailSending(false);
        reset();
      })
      .catch(error => {
        setEmailSuccess(false);
        setEmailError(true);
        setEmailSending(false);
      });
  };

  function handleSlideChange(e) {
    //
    // console.log(e);
    setCurrentSlideSize(e);
  }

  return (
    <Box className={classes.root} {...otherProps} ref={scrollableRef}>
      <Box className={classes.container}>
        <Box className={classes.section}>
          <Box className={classes.sectionScroll}>
            <Box className={classes.header} id="parent">
              <Stack
                align="center"
                justify="center"
                sx={{ position: 'relative' }}>
                <AppLogo
                  color="white"
                  width={200}
                  className={classes.appLogoPosition}
                />
                <Box className={classes.brand} id="aboutRef">
                  <Box className={classes.brandAlign}>
                    <Text
                      weight={400}
                      className={classes.brandSmall}
                      tt="uppercase">
                      Excellence In Everithing We Do
                    </Text>
                    <Text weight={900} className={classes.brandTitle}>
                      Creating Brand-Focused Business Solutions
                    </Text>
                    <Text weight={500} className={classes.brandSubTitle}>
                      We make your business better by building products that
                      people love to use.
                    </Text>
                  </Box>
                </Box>
              </Stack>
              <Stack p={0} spacing={0} sx={{ position: 'relative' }}>
                <Box className={classes.menu}>
                  <Group
                    spacing={40}
                    position="center"
                    className={classes.menuFlex}>
                    <AppLink onClick={() => scrollInto('aboutRef')}>
                      About
                    </AppLink>
                    <AppLink onClick={() => scrollInto('appsRef')}>
                      Apps
                    </AppLink>
                    <AppLink onClick={() => scrollInto('ourProcessRef')}>
                      Our Process
                    </AppLink>
                    <AppLink onClick={() => scrollInto('portfolioRef')}>
                      Portfolio
                    </AppLink>

                    <AppLink onClick={() => scrollInto('partnersRef')}>
                      Partners
                    </AppLink>
                    <AppLink onClick={() => scrollInto('contactRef')}>
                      Contact
                    </AppLink>
                  </Group>
                </Box>
                <Box className={classes.fill}>
                  <Image src={coverPhoto1} className={classes.fillImg} />
                </Box>
              </Stack>
            </Box>
            <Carousel
              maw="100%"
              controlSize={50}
              controlsOffset={25}
              loop
              nextControlIcon={
                <IconChevronRight
                  size={32}
                  color="#4D1BA9"
                  className={classes.controlPrevNext}
                />
              }
              previousControlIcon={
                <IconChevronLeft
                  size={28}
                  color="#4D1BA9"
                  className={classes.controlPrevNext}
                />
              }>
              <Carousel.Slide>
                <Box className={classes.headerApp}>
                  <Stack align="center" justify="center" spacing={0}>
                    <Box
                      className={classes.brandAppLayout}
                      id="appsRef"
                      // style={{ opacity: window.scrollY < 30 ? 1 : 0 }}
                    >
                      <Stack spacing={15} className={classes.appFlex}>
                        <Image
                          src={appFaxThatSheetLogo}
                          width={120}
                          withPlaceholder
                        />
                        <Text
                          weight={400}
                          size={80}
                          className={classes.appTextSizes}>
                          Discover
                        </Text>
                        <Text
                          weight={900}
                          size={80}
                          mt={-50}
                          className={classes.appTextSizes}>
                          Fax That Sheet
                        </Text>
                        <Text weight={400} size="md">
                          Fax That Sheet - Best Online Fax Service to send and
                          receive fax from iPhone. It’s easy, fast, reliable,
                          and secure.
                        </Text>
                        <Text weight={500} size="sm" mt={50}>
                          Download App & Start Exploring It
                        </Text>
                        <Image
                          src={appStoreBadge}
                          width={175}
                          withPlaceholder
                        />
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack
                    align="center"
                    justify="center"
                    p={90}
                    spacing={0}
                    className={classes.brandApp}>
                    <Box className={classes.fill}>
                      <Image src={coverApp1} className={classes.fillImg} />
                    </Box>
                  </Stack>
                </Box>
              </Carousel.Slide>
              <Carousel.Slide>
                <Box className={classes.headerApp}>
                  <Stack align="center" justify="center" spacing={0}>
                    <Box
                      className={classes.brandAppLayout}
                      id="appsRef"
                      // style={{ opacity: window.scrollY < 30 ? 1 : 0 }}
                    >
                      <Stack spacing={15} className={classes.appFlex}>
                        <Image
                          src={appFaxThatSheetLogo}
                          width={120}
                          withPlaceholder
                        />
                        <Text
                          weight={400}
                          size={80}
                          className={classes.appTextSizes}>
                          Discover
                        </Text>
                        <Text
                          weight={900}
                          size={80}
                          mt={-50}
                          className={classes.appTextSizes}>
                          Scanner Pro
                        </Text>
                        <Text weight={400} size="md">
                          Fax That Sheet - Best Online Fax Service to send and
                          receive fax from iPhone. It’s easy, fast, reliable,
                          and secure.
                        </Text>
                        <Text weight={500} size="sm" mt={50}>
                          Download App & Start Exploring It
                        </Text>
                        <Image
                          src={appStoreBadge}
                          width={175}
                          withPlaceholder
                        />
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack
                    align="center"
                    justify="center"
                    p={90}
                    spacing={0}
                    className={classes.brandApp}>
                    <Box className={classes.fill}>
                      <Image src={coverApp1} className={classes.fillImg} />
                    </Box>
                  </Stack>
                </Box>
              </Carousel.Slide>
            </Carousel>
          </Box>
        </Box>

        <Box className={classes.dark}>
          <Box className={classes.processBg}>
            <Stack spacing={0} align="center" className={classes.processStack}>
              <Text
                weight={900}
                size={60}
                color="white"
                id="ourProcessRef"
                className={classes.titleFlex}>
                Our Process
              </Text>
              <Group spacing={90} mt={130} className={classes.processFlex}>
                <AppProcess
                  icon={<IconChartDots color={themeColors.purple} size={24} />}
                  title="Research & Data Analysis">
                  Explore our approach to data-driven development. Harnessing
                  advanced research and analytics, we create innovative mobile
                  solutions tailored to your needs.
                </AppProcess>

                <AppProcess
                  icon={
                    <IconBrandCitymapper
                      color={themeColors.purple}
                      size={24}
                      style={{ rotate: '90deg' }}
                    />
                  }
                  title="User Flow & Journey Map">
                  Unravel your customers' journey. We design precise user flow
                  maps, ensuring smooth interactions with our mobile
                  applications, enhancing user satisfaction.
                </AppProcess>

                <AppProcess
                  icon={<IconTools color={themeColors.purple} size={24} />}
                  title="UX Design & Design System">
                  Immerse in our UX design process. We create intuitive
                  interfaces with a cohesive design system, ensuring a seamless,
                  engaging mobile app experience.
                </AppProcess>

                <AppProcess
                  icon={<IconBrush color={themeColors.purple} size={24} />}
                  title="UI & Visual Design Feedback">
                  Help shape your app's look and feel. We value your input on UI
                  and visual design to build aesthetically pleasing and
                  user-friendly mobile applications.
                </AppProcess>
              </Group>
            </Stack>
          </Box>
          {/* <Space h={30} /> */}
        </Box>
        <Box className={classes.purple} py={80} sx={{ position: 'relative' }}>
          <Stack spacing={100} align="center">
            <Text
              weight={900}
              size={60}
              color="white"
              className={classes.titleFlex}>
              Portfolio
            </Text>

            <Carousel
              id="portfolioRef"
              withIndicators
              w="100vw"
              // withControls
              height="100%"
              slideSize="33.333333%"
              slideGap="xl"
              slidesToScroll={1}
              initialSlide={3}
              loop
              align="center"
              onSlideChange={handleSlideChange}
              classNames={stylesCarousel}>
              <Carousel.Slide size={360}>
                <AppCard
                  title="Phone Protector"
                  image={phoneProtectorCover1}
                  size={360}>
                  Phone Protector: Your Mobile Shield! Offering robust security,
                  data backup, and optimization for a seamless and safe
                  smartphone experience.
                </AppCard>
              </Carousel.Slide>
              <Carousel.Slide size={360}>
                <AppCard title="Call Recorder" image={portfolioIco1} size={360}>
                  Call Recorder: Never Miss a Detail! Conveniently record, save
                  and replay your phone conversations with ease and clarity."
                </AppCard>
              </Carousel.Slide>

              <Carousel.Slide size={360}>
                <AppCard title="Scanner Pro" image={portfolioIco2} size={360}>
                  Scanner Pro: Your Pocket Document Digitizer! Convert paper
                  documents into digital files effortlessly with crystal-clear
                  quality.
                </AppCard>
              </Carousel.Slide>
              <Carousel.Slide size={360}>
                <AppCard
                  title="2nd Phone Number"
                  image={portfolioIco2}
                  size={160}>
                  Second Phone Number: Manage your professional and personal
                  life distinctly with an additional line on your same device.
                </AppCard>
              </Carousel.Slide>

              <Carousel.Slide size={360}>
                <AppCard
                  title="Signature Sign"
                  image={portfolioIco2}
                  size={360}>
                  Signature Sign: Digital Autographs Made Easy! Seamlessly sign,
                  send, and manage your documents with your personalized
                  e-signature.
                </AppCard>
              </Carousel.Slide>
            </Carousel>
          </Stack>
        </Box>
        <Box className={classes.white} py={80}>
          <Center mb={50}>
            <Text weight={900} size={60}>
              Partners
            </Text>
          </Center>
          <Box id="partnersRef" className={classes.partnersLogos}>
            <Box>
              <Image src={lagoPartner1} width={100} opacity={0.8} />
            </Box>
            <Box>
              <Image src={lagoPartner2} width={100} opacity={0.8} />
            </Box>
            <Box>
              <Image src={lagoPartner3} width={130} opacity={0.8} />
            </Box>
            <Box>
              <Image src={lagoPartner4} width={100} opacity={0.8} />
            </Box>
            <Box>
              <Image src={lagoPartner5} width={30} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner6} width={70} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner7} width={90} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner8} width={120} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner9} width={100} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner10} width={40} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner11} width={110} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner12} width={110} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner13} width={100} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner14} width={100} opacity={0.8} />
            </Box>

            <Box>
              <Image src={lagoPartner15} width={100} opacity={0.8} />
            </Box>
          </Box>
        </Box>
        <Box className={classes.dark} py={120}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.contactFlex}>
              <Text
                weight={900}
                size={60}
                color="white"
                className={classes.titleFlex}>
                Get In Touch
              </Text>

              <Group className={classes.formFlex} id="contactRef">
                <Stack spacing={10} align="center">
                  <ThemeIcon
                    radius="xl"
                    size="xl"
                    color={themeColors.lightDark}>
                    <IconMessage size="17" />
                  </ThemeIcon>
                  <Text color="white" size="sm" className={classes.text}>
                    If you have any questions, just reach out to us and we’ll
                    respond as soon as we can.
                  </Text>

                  <Text
                    color="lightGray"
                    size="sm"
                    className={classes.darkDescription}>
                    Please provide as much information as possible.
                  </Text>
                  <Stack align="left" spacing={20}>
                    <Stack spacing={5}>
                      <Text color="white" size="sm">
                        Name
                      </Text>
                      <TextInput
                        classNames={classes}
                        variant="unstyled"
                        {...register('Name', {
                          required: true,
                          minLength: 2,
                          maxLength: 20,
                        })}
                      />
                      {errors.Name && (
                        <Text weight={500} color="red" pl={15}>
                          {errors.Name?.message}
                        </Text>
                      )}
                    </Stack>

                    <Stack spacing={5}>
                      <Text color="white" size="sm">
                        Email
                      </Text>
                      <TextInput
                        classNames={classes}
                        variant="unstyled"
                        {...register('Email')}
                      />

                      {errors.Email && (
                        <Text weight={500} color="red" pl={15}>
                          {errors.Email?.message}
                        </Text>
                      )}
                    </Stack>

                    <Stack spacing={5}>
                      <Text color="white" size="sm">
                        Subject
                      </Text>
                      <TextInput
                        classNames={classes}
                        variant="unstyled"
                        {...register('Subject')}
                      />
                      {errors.Subject && (
                        <Text weight={500} color="red" pl={15}>
                          {errors.Subject?.message}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={10} align="center">
                  <ThemeIcon
                    radius="xl"
                    size="xl"
                    color={themeColors.lightDark}>
                    <IconMail size="17" />
                  </ThemeIcon>

                  <Anchor
                    component={Link}
                    to="mailto:hello@trendestinsight.com">
                    <Text color="white" size="sm">
                      hello@trendestinsight.com
                    </Text>
                  </Anchor>

                  <Text
                    color="lightGray"
                    size="xs"
                    className={classes.darkDescription}>
                    24 X 7 online support
                  </Text>
                  <Stack align="left" spacing={20}>
                    <Stack spacing={5}>
                      <Text color="white" size="sm" align="left">
                        Message
                      </Text>
                      <Textarea
                        // color="white"
                        variant="unstyled"
                        minRows={14}
                        maxRows={18}
                        classNames={inputArea}
                        {...register('Message')}
                      />
                      {errors.Message && (
                        <Text weight={500} color="red" pl={15}>
                          {errors.Message?.message}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Group>
              <Stack align="center" spacing={10}>
                {emailSending && (
                  <Stack align="center">
                    <Loader />
                    <Text weight={400} color="white">
                      Sending...
                    </Text>
                  </Stack>
                )}
                {emailSuccess && (
                  <Notification
                    icon={<IconCheck size={18} />}
                    color="teal"
                    onClose={() => setEmailSuccess(false)}>
                    <Text size="md">
                      Your message has been sent successfuly
                    </Text>
                  </Notification>
                )}
                {emailError && (
                  <Notification
                    icon={<IconX size={18} />}
                    color="red"
                    onClose={() => setEmailError(false)}>
                    <Text size="md">Your message has not been sent</Text>
                  </Notification>
                )}
                <Group className={classes.buttonResponsive} spacing={0}>
                  <Button
                    disabled={emailSending}
                    color="violet"
                    radius="xs"
                    size="md"
                    type="submit"
                    fullWidth
                    className={classes.button}>
                    <Text weight={600} size="xs">
                      Send
                    </Text>
                  </Button>
                </Group>
              </Stack>
            </Box>
          </form>
        </Box>
        {/* <ActionIcon
          className={classes.scroll}
          color="lightgray"
          variant="transparency"
          radius="md"
          size={50}
          onClick={() => scrollInto('aboutRef')}>
          <IconCircleArrowUp size={50} stroke={1} />
        </ActionIcon> */}
        <Box className={classes.footer} py={50}>
          <AppLogo color="black" className={classes.appLogoSmall} />
          <Group spacing={60} position="center" w={400}>
            <Anchor className={classes.anchor} component={Link} to="/tos/">
              <Text size="sm">Terms & Conditions</Text>
            </Anchor>
            <Anchor className={classes.anchor} component={Link} to="/privacy/">
              <Text size="sm">Privacy Policy</Text>
            </Anchor>
          </Group>
          <Text size="md" sx={{ whiteSpace: 'nowrap' }}>
            ©2023 Trendest Insight All Rights Reserved
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default HomePage;

const useStylesCarousel = createStyles({
  indicators: {
    bottom: -45,
  },
  control: {
    // borderRadius: 0,
    // padding: 25,
    // boxShadow: 'none',
    display: 'none',
  },
});

const useStylesInput = createStyles({
  input: {
    backgroundColor: '#191919',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 0,
    border: '1px solid #282828',
    color: '#fff',
    height: 250,
    width: 500,
    fontSize: 14,
    '&:focus': {
      border: '1px solid #4D1BA9',
      WebkitTransition: '200ms',
    },
    '::-webkit-scrollbar': {
      width: 10,
    },

    '::-webkit-scrollbar-track': {
      background: '#0F0F0F',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#575757',
      borderRadius: 18,
    },

    '@media (max-width: 768px)': {
      minWidth: '90vw',
      width: '100%',
    },
  },
});

const useStyles = createStyles({
  root: {
    fontFamily: 'Soleil',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',

    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  content: {
    width: 1440,
    // margin: '0 auto',
  },
  container: {
    width: '100%',
    // maxWidth: '1440px',
    margin: '0 auto',
    backgroundColor: themeColors.dark,
  },
  footer: {
    minHeight: 150,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: 150,
    paddingRight: 150,
    backgroundColor: 'white',
    '@media (max-width: 1023px) ': {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  anchor: {
    color: themeColors.black,
    fontSize: 'sm',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    backgroundColor: themeColors.dark,
    justifyItems: 'stretch',
    color: 'white',

    '@media (max-width: 1023px)': {
      gridTemplateColumns: '1fr',
      // paddingLeft: 20,
      // paddingRight: 20,
    },
    '@media (min-width: 1024px)': {
      // paddingLeft: 150,
    },

    // '@media (max-width: 1023px)': {
    //   paddingLeft: 0,
    //   paddingRight: 0,
    // },
  },

  headerApp: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    justifyItems: 'stretch',
    color: themeColors.black,
    backgroundColor: themeColors.lightGray,
    gap: 0,

    '@media (max-width: 1023px)': {
      gridTemplateColumns: '1fr',
    },
    '@media (min-width: 1024px)': {
      // paddingLeft: 150,
    },
  },

  dark: {
    backgroundColor: themeColors.dark,
  },

  purple: {
    backgroundColor: themeColors.purple,
  },

  white: {
    backgroundColor: themeColors.white,
  },

  formFlex: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 60,
    '@media (max-width: 1023px)': {
      flexDirection: 'column',
      gap: 30,
      alignItems: 'center',
    },
  },

  darkDescription: {
    color: themeColors.darkGray,
  },

  input: {
    backgroundColor: '#191919',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 0,
    border: '1px solid #282828',
    color: '#fff',
    fontSize: 14,
    minHeight: 50,
    minWidth: 500,
    input: {
      color: 'white',
    },
    '&:focus': {
      border: '1px solid #4D1BA9',
      WebkitTransition: '200ms',
    },

    '@media (max-width: 768px)': {
      minWidth: '90vw',
    },
  },
  button: {
    width: 500,
    backgroundColor: themeColors.purple,
    // '@media (max-width: 427px)': {
    //   // paddingLeft: 20,
    //   // paddingRight: 20,
    //   maxWidth: '90vw',
    // },

    '@media (max-width: 767px)': {
      maxWidth: '90vw',
      // paddingLeft: 20,
      // paddingRight: 20,
      // maxWidth: '90vw',
      // width: '100%',
    },
  },

  buttonResponsive: {},
  processBg: {
    background: `url(${processBg}) center no-repeat`,
    backgroundSize: 'cover',
    height: '100%',

    '@media (max-width: 1023px)': {
      backgroundImage: 'none',
    },
  },

  processStack: {
    paddingTop: 100,
    paddingBottom: 140,

    '@media (max-width: 1023px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  brandAlign: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,

    '@media (max-width: 1023px)': {
      marginLeft: 0,
    },
    '@media (min-width: 1024px)': {
      marginLeft: 50,
      marginRight: 20,
      marginTop: 20,
    },
    '@media (max-width: 1023px)': {
      gap: 15,
      marginBottom: 20,
    },
    '@media (min-width: 1440px)': {
      marginLeft: '10%',
      marginRight: 20,
      marginTop: 20,
    },
  },

  brand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    gap: 40,
  },

  brandApp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    gap: 40,
    backgroundColor: '#4D1BA9',
    '@media (max-width: 1023px)': {
      gap: 15,
    },
  },

  brandSmall: {
    marginTop: 100,
    fontSize: '1vw',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: '4px',
    color: '#5416b0',

    '@media (max-width: 320px)': {
      fontSize: 9,
      textAlign: 'center',
    },
    '@media (min-width: 321px) and (max-width: 767px) ': {
      fontSize: 14,
      textAlign: 'center',
    },

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 20,
      textAlign: 'center',
    },
    '@media (min-width: 1024px) and (max-width: 1440px) ': {
      marginTop: 40,
    },
  },
  brandTitle: {
    fontSize: '3.5vw',
    lineHeight: 1.19,
    letterSpacing: 'normal',

    '@media (max-width: 1023px)': {
      // fontSize: 34,
      textAlign: 'center',
      alignItems: 'center',
    },

    '@media (max-width: 320px)': {
      // fontSize: 30,
      textAlign: 'center',
    },
    '@media (min-width: 321px) and (max-width: 767px) ': {
      // fontSize: 38,
      textAlign: 'center',
    },

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      // fontSize: 54,
    },
  },
  brandSubTitle: {
    fontSize: '2vw',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',

    '@media (max-width: 1023px)': {
      // fontSize: 16,
      textAlign: 'center',
    },

    '@media (min-width: 1440px)': {
      // fontSize: 24,
      // maxWidth: 600,
    },
    '@media (min-width: 1600px)': {
      // fontSize: 30,
      // maxWidth: 700,
    },

    '@media (max-width: 1023px)': {
      // fontSize: 16,
      textAlign: 'center',
    },
    '@media (max-width: 425px)': {
      // fontSize: 14,
      textAlign: 'center',
    },
  },
  section: {
    marginLeft: 'auto',
    marginRight: 'auto',
    // width: '90vw',
    // maxWidth: 1600,
    // height: '100%',
  },
  sectionScroll: {},

  logo: {
    position: 'absolute',
    top: 30,
    left: 50,
  },
  menu: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1000,

    '@media (max-width: 1023px)': {
      display: 'none',
    },
  },
  appLogoPosition: {
    position: 'absolute',
    top: 30,
    left: '10%',
    width: '100%',
    zIndex: 1000,
    '@media (max-width: 1023px)': {
      position: 'relative',
      display: 'flex',
      justifyItems: 'center',
    },
  },

  appLogoSmall: {
    // width: 300,
    // position: 'absolute',
    '@media (max-width: 424px)': {
      minWidth: 100,
    },
  },

  menuFlex: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: 125,
    flexWrap: 'nowrap',

    '@media (min-width: 1024px)': {
      height: 80,
    },
    '@media (min-width: 1440px)': {
      height: 100,
    },
    '@media (min-width: 1600px)': {
      height: 125,
    },
  },
  scroll: {
    position: 'absolute',
    bottom: 60,
    right: 70,
  },

  processFlex: {
    justifyContent: 'center',
    '@media (max-width: 1023px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },

  brandAppLayout: {
    paddingLeft: 30,
    paddingRight: 30,

    '@media (min-width: 320px) and (max-width: 768px) ': {
      paddingTop: 20,
      paddingBottom: 30,
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  appFlex: {
    '@media (min-width: 320px) and (max-width: 1023px) ': {
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },

    '@media (min-width: 769px) ': {
      paddingTop: 20,
      paddingBottom: 30,
    },
  },
  appTextSizes: {
    fontSize: '4em',
    '@media (min-width: 320px) and (max-width: 1023px) ': {
      fontSize: 34,
      marginTop: 0,
      textAlign: 'center',
    },
  },
  tosFlex: {
    '@media (max-width: 1023px) ': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  fill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  fillImg: {
    flexShrink: 0,
    minWidth: '100%',
    minHeight: '100%',
  },

  partnersLogos: {
    WebKitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',

    // display: 'grid',
    // gridTemplateColumns: 'repeat(6, 1fr);',
    // gridTemplateRows: 'auto',

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    gap: 50,
  },

  contactFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 70,

    '@media (max-width: 1023px) ': {
      // display: 'flex',
      // flexDirection: 'row',
    },

    // '@media (max-width: 427px)': {
    //   maxWidth: '90%',
    // },

    '@media (max-width: 427px)': {
      gap: 20,
    },
  },

  titleFlex: {
    fontSize: 60,
    '@media (max-width: 427px)': {
      fontSize: '10vw',
    },
  },

  text: {
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'center',
    '@media (max-width: 427px)': {
      // fontSize: '4vw',
      // width: '100vw',
      // fontSize: '3vw',
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  controlPrevNext: {
    // position: 'absolute',
    // top: 200,
  },
});
