import React from 'react';
import { createStyles, Box, Text, List, Table, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';

function PrivacyPlain({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <h4>
        <strong>I. INTRODUCTION. REGIONAL PATTERNS (CALIFORNIA)</strong>
      </h4>

      <Text py={10} weight={400}>
        Trendest Insight SRL(&ldquo;we,&rdquo; &ldquo;us&rdquo; or
        &ldquo;our&rdquo;) takes your privacy seriously. This Privacy policy
        (&ldquo;Privacy policy&rdquo;) explains our data protection policy and
        describes the types of information we may process when you access and/or
        use the Website available at&nbsp;
        <Anchor component={Link} to="https://trendestinsight.com/">
          https://trendestinsight.com/
        </Anchor>
        &nbsp;(hereinafter, the &ldquo;Website&rdquo;).
      </Text>

      <Text py={10} weight={400}>
        When we refer to personal data (or personal information) we mean any
        information of any kind relating to a natural person who can be
        identified, directly or indirectly, in particular by reference to such
        data. It is a natural person who can be identified directly or
        indirectly, in particular by reference to an identification number or to
        one or more factors specific to his or her physical, physiological,
        mental, economic, cultural or social status.
      </Text>

      <Text py={10} weight={400}>
        Our Privacy policy applies to all users and others who access the
        Website (&ldquo;Users&rdquo;).
      </Text>

      <Text py={10} weight={400}>
        For the purposes of the GDPR, we are the data controller, unless
        otherwise stated.
      </Text>

      <Text py={10} weight={400}>
        PLEASE READ CAREFULLY THE FOLLOWING PRIVACY POLICY, FOR INFORMATION
        REGARDING THE WAYS YOUR PERSONAL INFORMATION MAY BE PROCESSED. WHEN YOU
        USE THE WEBSITE YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND
        AGREED TO BE BOUND BY THIS PRIVACY POLICY.
      </Text>

      <Text py={10} weight={400}>
        <strong>
          IF YOU ARE A CALIFORNIA RESIDENT PLEASE READ THE FOLLOWING IMPORTANT
          NOTICE
        </strong>
      </Text>

      <Text py={10} weight={400}>
        Under the California Consumer Privacy Act of 2018 (CCPA) California
        residents shall have the right to request:
      </Text>

      <Text py={10} weight={400}>
        &ndash; the categories of personal information that is processed;{' '}
      </Text>

      <Text py={10} weight={400}>
        &ndash; the categories of sources from which personal information is
        obtained;
      </Text>

      <Text py={10} weight={400}>
        &ndash; the purpose for processing of user personal data;{' '}
      </Text>

      <Text py={10} weight={400}>
        &ndash; the categories of third parties with whom we may share your
        personal information;
      </Text>

      <Text py={10} weight={400}>
        &ndash; the specific pieces of personal information that we might have
        obtained about particular user provided that the data
      </Text>

      <Text py={10} weight={400}>
        given in the request is reliable enough and allows us to identify the
        user.
      </Text>

      <Text py={10} weight={400}>
        Please use the navigation links through this Privacy Policy:{' '}
      </Text>

      <Text py={10} weight={400}>
        <strong>PERSONAL INFORMATION</strong>
      </Text>

      <Text py={10} weight={400}>
        All about the categories of information, its sources and purposes of
        processing&nbsp;
        <strong>
          <Anchor
            component={Link}
            to="https://trendestinsight.com/privacy#info-we-process">
            &gt;&gt;
          </Anchor>
        </strong>
      </Text>

      <Text py={10} weight={400}>
        Please mind that according to CCPA personal information does not include
        de-identified or aggregated consumer information.
      </Text>

      <Text py={10} weight={400}>
        <strong>SHARING</strong>
      </Text>

      <Text py={10} weight={400}>
        How your information can be shared&nbsp;
        <strong>
          <Anchor
            component={Link}
            to="https://trendestinsight.com/privacy#sharing-info">
            &gt;&gt;
          </Anchor>
        </strong>
      </Text>

      <Text py={10} weight={400}>
        Please note that all third parties that are engaged in processing user
        data are service providers that use such information on the basis of
        agreement and pursuant to business purpose.
      </Text>

      <Text py={10} weight={400}>
        <strong>OPT-OUT OPTIONS</strong>
      </Text>

      <Text py={10} weight={400}>
        If you don&rsquo;t want us to collect and/or process your information
        any more please contact us at&nbsp;
        <Anchor component={Link} to="mailto:support@trendestinsight.com">
          support@trendestinsight.com
        </Anchor>
        . Note that if you decide to opt out of data collection and processing
        this can affect some Website functions and you may not be able to use
        all features of the Website to the fullest extent possible.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        <strong>REQUESTS</strong>
      </Text>

      <Text py={10} weight={400}>
        To submit a verifiable consumer request for access, portability or
        deletion of personal data please contact us at&nbsp;
        <Anchor component={Link} to="mailto:support@trendestinsight.com">
          support@trendestinsight.com
        </Anchor>
        . Please include in the text of your appeal the wording &ldquo;Your
        rights to maintain confidentiality in the state of California&rdquo;.
      </Text>

      <Text py={10} weight={400}>
        When submitting a verifiable request, you should be ready to:{' '}
      </Text>

      <Text py={10} weight={400}>
        &bull; Provide sufficient information that allows us to reasonably
        verify you are the person about whom we collected personal information
        or an authorized representative, which may include: name, address, city,
        state, and zip code and email address. We may use this information to
        surface a series of security questions to you to verify your identity.
        If you are making a request through an authorized agent acting on your
        behalf, such authorized agent must provide written authorization
        confirming or a power of attorney, signed by you.
      </Text>

      <Text py={10} weight={400}>
        &bull; Describe your request with sufficient detail that allows us to
        properly understand, evaluate, and respond to it.
      </Text>

      <Text py={10} weight={400}>
        We will not be able to respond to your request or provide you with
        personal information if we cannot: (i) verify your identity or authority
        to make the request; or (ii) confirm the personal information relates to
        you. We may ask you for additional information or documents to verify
        your identity. We may also carry out checks, including with third party
        identity verification services, to verify your identity before taking
        any action with your personal information. This is regarded as a
        safeguard measure to prevent disclosure of your personal information
        under a fake or scum request. We ensure that personal information
        provided in a verifiable consumer request will be used only to verify
        the requestor&rsquo;s identity or authority to make the request and not
        for any other purpose. We will keep it for the adequate term reasonably
        needed for the purpose described above and delete after the purpose is
        fulfilled.
      </Text>

      <Text py={10} weight={400}>
        We try to respond to a verifiable consumer request within forty-five
        (45) days of its receipt. If we require more time, we will inform you of
        the reason and extension period in writing. Please note that we are only
        required to respond to two requests per customer each year.
      </Text>

      <Text py={10} weight={400}>
        <strong>EQUAL RIGHTS</strong>
      </Text>

      <Text py={10} weight={400}>
        Nothing in the way we deal with your request shall be interpreted as
        discrimination, which means that we will not set up different pricing or
        products, or different level or quality of services for you, if you
        choose to exercise your rights. However, in some circumstances, we may
        not be able to provide services if you choose to delete your personal
        information from our records.
      </Text>

      <Text py={10} weight={400}>
        <strong>SALE OF DATA</strong>
      </Text>

      <Text py={10} weight={400}>
        We do not sell any of your personal data to third parties.{' '}
      </Text>

      <Text py={10} weight={400}>
        &nbsp;{' '}
      </Text>

      <h4>
        <strong>II. INFORMATION WE PROCESS</strong>
      </h4>

      <Text py={10} weight={400}>
        There are several categories of information that may be processed when
        you access and/or use the Website.
      </Text>

      <Text py={10} weight={400}>
        <strong>Information that you voluntarily provide to us</strong>
      </Text>

      <Text py={10} weight={400}>
        We may collect, store and process some information about you when you
        use the &ldquo;Contacts&rdquo; form available on the Website. In
        particular, if you decide to make a request via the
        &ldquo;Contacts&rdquo; form, the following categories of information are
        voluntarily provided by you: your name, email address and the text of
        your message. You may also choose the type of your request among the
        proposed options (Customer Support/Media Contact/Advertising/General
        Contact). We use the services of Amazon Web Services, Inc. (a subsidiary
        of Amazon.com Inc.) or of Advanced Hosters B.V. in order to collect,
        store and process the information that you provide us via the
        &ldquo;Contacts&rdquo; form available on the Website. We use the
        information submitted by you via the &ldquo;Contacts&rdquo; form solely
        for the purpose of considering and responding to your requests (if
        necessary).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Text>

      <Text py={10} weight={400}>
        <strong>Information that is processed automatically&nbsp;</strong>
      </Text>

      <Text py={10} weight={400}>
        When you access and/or use the Website, some information about your
        device and your user behavior may be processed automatically. This
        information is generally non-personal, i.e. it does not, on its own,
        permit direct association with any specific individual, and we may
        access it only in aggregated form. We process this information on the
        ground of our&nbsp;
        <strong>legitimate interest</strong>&nbsp;in improving our Website and
        giving our users the best experience. If we do not access such data we
        may not be able to provide you with all features of the Website.
      </Text>

      <Text py={10} weight={400}>
        We use third-party automatic data processing technologies to analyze
        certain information sent by your device via our Website (analytics or
        advertising tools). Some of them may launch automated processing of your
        personal data, including profiling, which means any form of automated
        processing of personal data used to evaluate certain personal aspects
        relating to you, in particular to analyze or predict aspects concerning
        your personal preferences, interests, behavior, location or movements
        (see the list of data described below). Processing information through
        automatic data processing technologies starts automatically when you
        first time access the Website.
      </Text>

      <List>
        <List.Item>
          <strong>Device Details.&nbsp;</strong>When you use a device (computer,
          laptop / tablet / phone) to access our Website, some details about
          your device are reported, including &ldquo;device identifiers&rdquo;.
          Device identifiers are small data files or similar data structures
          stored on or associated with your device, which uniquely identify your
          device (but not your personality). Device identifier enables
          generalized reporting or personalized content and ads by the third
          parties.
          <Text py={10} weight={400}>
            What data can be processed:{' '}
          </Text>
          <List>
            <List.Item>
              Information about the device itself: type of your device, type of
              operating system and its version, model and manufacturer, screen
              size, screen density, orientation, audio volume and battery,
              device memory usage.
            </List.Item>
            <List.Item>
              Information about the internet connection: mobile carrier, network
              provider, network type, IP address, timestamp and duration of
              sessions, speed, browser and version, browser
              language.&nbsp;&nbsp;
            </List.Item>
            <List.Item>
              Location-related information: IP address, the country code/
              region/ state/ city associated with your SIM card or your device,
              language setting, time zone.&nbsp;&nbsp;
            </List.Item>
            <List.Item>
              Device identifiers: advertising identifiers, Identity For
              Advertisers for iOs devices/ Advertising ID or Android ID for
              Android devices.
            </List.Item>
          </List>
        </List.Item>
        <List.Item>
          <strong>Cookies and similar technologies.</strong>&nbsp;When you
          access and/or use the Website, cookies and similar technologies may be
          used (pixels, web beacons, scripts). A cookie is a text file
          containing small amounts of information which is downloaded to your
          device when you access the Website. The text file is then sent back to
          the server each time you use the Website. This enables us to operate
          the Website more effectively. For example, we will know how many users
          access specific areas, content or features within our Website and
          which links they clicked on. We use this aggregated information to
          understand and optimize how our Website is used, improve our marketing
          efforts, and provide content and features that are of interest to you.
          Third party analytics tools use cookies or similar technologies for
          the purpose of analyzing the Website traffic.&nbsp;
        </List.Item>
        <List.Item>
          <strong>Log file information.&nbsp;</strong>Log file information is
          automatically reported each time you make a request to access the
          Website. When you use our Website, analytics tools automatically
          record certain log file information, including time and date when you
          start and stop using the Website, and how you interact with the
          Website.
          <Text py={10} weight={400}>
            Information provided automatically to analytics or advertising tools
            does not generally come to our control, therefore, we cannot be
            responsible for processing such information. Please mind that some
            services are engaged in personal data profiling and may obtain
            information related to your personality and/or your device by using
            technologies that do not belong to our scope of
            responsibility.&nbsp;
          </Text>
        </List.Item>
      </List>

      <h4>
        <strong>III. THE PURPOSES OF PROCESSING YOUR DATA</strong>
      </h4>

      <Text py={10} weight={400}>
        Our mission is to constantly improve our Website and provide you with
        better user experiences. As part of this mission, we use your
        information for the following purposes:
      </Text>

      <Text py={10} weight={400}>
        (a)&nbsp;
        <strong>
          To improve, test and monitor the effectiveness of our Website
        </strong>
        . We use the information that is processed automatically to better
        understand user behavior and trends, detect potential outages and
        technical issues, to operate, protect, improve, and optimize our
        Website.
      </Text>

      <Text py={10} weight={400}>
        (b)&nbsp;<strong>To communicate with you.&nbsp;</strong>We may use
        information that you voluntarily provide to us via the
        &ldquo;Contacts&rdquo; form available on the Website for the purposes of
        processing and responding (if necessary) to your requests, receiving
        your feedback, comments or suggestions about the Website or on other
        matters.&nbsp;&nbsp;
      </Text>

      <Text py={10} weight={400}>
        (c)&nbsp;
        <strong>To prevent fraud and spam, to enforce the law</strong>. We
        really want our Website to be free of spam and fraudulent content so
        that you feel safe and free. We may use your information to prevent,
        detect, and investigate fraud, security breaches, potentially prohibited
        or illegal activities, protect our trademarks, enforce our Terms of Use
        and applicable law.
      </Text>

      <Text py={10} weight={400}>
        If any new purpose for processing your data arises, we will let you know
        when we start to process information on that other purpose by
        introducing the corresponding changes to this Privacy policy.
      </Text>

      <h4>
        <strong>IV. SHARING YOUR INFORMATION</strong>
      </h4>

      <Text py={10} weight={400}>
        We will not rent or sell your personal data to third parties, but we may
        share your information obtained via tools like cookies, log files, and
        device identifiers with third-party organizations that provide automatic
        data processing technologies for the Website. We do not control or
        influence these third parties&rsquo; tracking technologies or how they
        may be used.
      </Text>

      <Text py={10} weight={400}>
        Please note that while we partner solely with third parties that gave us
        assurance of application of necessary technical and organizational
        measures to protect your data, we cannot guarantee the security of any
        information transmitted from the Website directly to such third parties.
        We are not responsible for any accidental loss or unauthorized access to
        your data through a fault of third parties.
      </Text>

      <Text py={10} weight={400}>
        We may engage the following third-party service providers in order to
        provide us with necessary infrastructure for delivery and improvement of
        our Website:
      </Text>

      <Table
        withBorder
        horizontalSpacing="xs"
        verticalSpacing="xs"
        fontSize="xs"
        sx={{
          wordBreak: 'break-all',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        }}>
        <thead>
          <tr>
            <th>ENTITY NAME</th>
            <th>SERVICES PERFORMED</th>
            <th>ENTITY LOCATION</th>
            <th>LINK TO PRIVACY POLICY</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Advanced Hosters B.V.</td>
            <td>Cloud service provider</td>
            <td>Netherlands</td>
            <td>
              <br />
              <Anchor
                component={Link}
                to="https://advancedhosting.com/en/documents/privacyPolicy">
                https://advancedhosting.com/en/documents/privacyPolicy
              </Anchor>
            </td>
          </tr>
          <tr>
            <td sx={{ wordBreak: 'break-all', wordWrap: 'break-word' }}>
              Amazon Web Services, Inc. (a subsidiary of Amazon.com Inc.)
            </td>
            <td>Cloud storage provider</td>
            <td>U.S.A.</td>
            <td>
              <br />
              <Anchor
                component={Link}
                to="https://sell.amazon.com/privacy.html?ref_=asus_soa_rd&amp;">
                https://sell.amazon.com/privacy.html?ref_=asus_soa_rd&amp;
              </Anchor>
              <br />
              <br />
              <br />
              Data Privacy FAQ:&nbsp;
              <br />
              <br />
              <Anchor
                component={Link}
                to="https://aws.amazon.com/compliance/data-privacy-faq/?nc1=h_ls">
                https://aws.amazon.com/compliance/data-privacy-faq/?nc1=h_ls
              </Anchor>
            </td>
          </tr>
          <tr>
            <td>Facebook Inc.</td>
            <td>Analytics and marketing service provider</td>
            <td>U.S.A.</td>
            <td>https://www.facebook.com/privacy/explanation</td>
          </tr>
          <tr>
            <td>Google LLC</td>
            <td>Google Analytics (tool for analysing Website traffic)</td>
            <td>U.S.A.</td>
            <td>
              Google Privacy Policy:
              <br />
              <br />
              <Anchor
                component={Link}
                to="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </Anchor>
              <br />
              <br />
              <br />
              How Google uses information from sites or apps that use its
              services:
              <br />
              <br />
              <Anchor
                component={Link}
                to="https://policies.google.com/technologies/partner-sites?hl=en">
                https://policies.google.com/technologies/partner-sites?hl=en
              </Anchor>
              <br />
              <br />
              <br />
              <br />
              <Anchor
                component={Link}
                to="https://support.google.com/analytics/answer/6004245">
                https://support.google.com/analytics/answer/6004245
              </Anchor>
              <br />
              <br />
              (see section &lsquo;Information for Visitors of Sites and Apps
              Using Google Analytics&rsquo;)
            </td>
          </tr>
          <tr>
            <td>
              Google reCAPTCHA (analytics engine for protection the Website from
              fraud and abuse, protection of Website users from bots)
            </td>
          </tr>
          <tr>
            <td>YANDEX LLC (Yandex.Metrica)</td>
            <td>Analytics service provider</td>
            <td>Russian Federation</td>
            <td>
              Privacy Policy:
              <br />
              <br />
              <Anchor
                component={Link}
                to="https://yandex.com/legal/confidential/">
                https://yandex.com/legal/confidential/
              </Anchor>
              <br />
              <br />
              <br />
              Yandex.Metrica Data Processing Agreement:
              <br />
              <br />
              <Anchor
                component={Link}
                to="https://yandex.com/legal/metrica_agreement/">
                https://yandex.com/legal/metrica_agreement/
              </Anchor>
            </td>
          </tr>
        </tbody>
      </Table>

      <Text py={10} weight={400}>
        As it is indicated above we use such services as Google Analytics and
        Yandex.Metrica that track and report the Website traffic. Both services
        use different types of cookies to analyze user activity. If you want to
        learn more about the categories of data collected and processed by
        analytics service providers please consult the corresponding websites of
        Google Analytics and Yandex.Metrica as well as their privacy policies
        and other documents concerning data protection. Note that we are not
        responsible for any usage of your data by the abovementioned third-party
        service providers in violation of our instructions.&nbsp;&nbsp;
      </Text>

      <Text py={10} weight={400}>
        If you don&rsquo;t want Google Analytics and/or Yandex.Metrica to use
        cookies you can block data collection following the instructions
        provided in Section IX of the present Privacy policy.
      </Text>

      <Text py={10} weight={400}>
        Our Website may contain links to third party sites/services. You also
        may visit the Website following a link from a third party site. We are
        not responsible for the privacy practices of these third-party sites or
        services linked, including for the information or content contained
        within them (unless we are the providers of those sites and/or
        services).&nbsp;&nbsp;
      </Text>

      <Text py={10} weight={400}>
        We may disclose your personal information if it is needed for objective
        reasons, due to the public interest or in other unforeseen
        circumstances:
      </Text>

      <List>
        <List.Item>as required by law;</List.Item>
        <List.Item>
          when we believe, in good faith, that disclosure is necessary to
          protect our rights, protect your safety or the safety of others,
          investigate fraud, or respond to a government request;
        </List.Item>
        <List.Item>
          if we are involved in a merger, acquisition, or sale of all or a
          portion of our assets, you will be notified via prominent notice on
          our Website of any change in ownership or uses of your personal
          information, as well as any choices you may have regarding your
          personal information;
        </List.Item>
        <List.Item>
          in some cases, we may share your information with our affiliates,
          subsidiaries, contractors, business partners or other third parties
          who perform services on our behalf.&nbsp;&nbsp;
        </List.Item>
      </List>

      <h4>
        <strong>V. INTERNATIONAL DATA TRANSFERS</strong>
      </h4>

      <h4>
        WE WORK IN THE CROSS-BORDER AREA AND PROVIDE OUR WEBSITE TO USERS AROUND
        THE WORLD.
      </h4>

      <Text py={10} weight={400}>
        We and third-party organizations that provide automatic data processing
        technologies for the Website or our third-party partners may transfer
        the automatically processed information across borders and from your
        country or jurisdiction to other countries or jurisdictions around the
        world.
      </Text>

      <Text py={10} weight={400}>
        If you are located in the European Union or other regions with laws
        governing data processing that may differ from U.S. law, please note
        that we may transfer information, including personal information, to a
        country and jurisdiction that may not have the same data protection laws
        as in your home jurisdiction. We try to make sure that the recipient of
        any personal data provides a proper protection of the personal data
        received, in accordance with the current legislation on the protection
        of such information.
      </Text>

      <Text py={10} weight={400}>
        This means that your personal information can be transferred to a third
        country, a territory or one or more specified sectors within that third
        country, or to the international organization where data protection and
        confidentiality regulations may not provide the same level of protection
        of personal data as your country does.
      </Text>

      <Text py={10} weight={400}>
        We try to make sure that the recipient of any personal data provides a
        proper protection of the personal data received, in accordance with the
        current legislation on the protection of such information. By using the
        Website, you agree that we may transfer your personal data to any third
        country, a territory or one or more specified sectors within that third
        country, or to the international organization.
      </Text>

      <h4>
        <strong>VI. HOW DO WE STORE YOUR DATA</strong>
      </h4>

      <Text py={10} weight={400}>
        For the purposes of data storage, we recourse to the services of the
        hosting organizations. We take your privacy seriously and, therefore,
        encrypt your personal data &ndash; if possible &ndash; before sending it
        to the hosting organizations for the purposes of its storage. Please
        note that we cooperate only with those hosting organizations that have
        passed our security and reliability check.
      </Text>

      <Text py={10} weight={400}>
        In particular, we recourse to the services of Amazon Web Services, Inc.
        (a subsidiary of Amazon.com Inc.) and/or of Advanced Hosters B.V. that
        have adopted technical and organizational measures to protect your
        personal data against unauthorized/unlawful processing and accidental
        loss, destruction or other damage.
      </Text>

      <h4>
        <strong>VII. HOW LONG WE USE YOUR PERSONAL DATA</strong>
      </h4>

      <Text py={10} weight={400}>
        We will generally keep your personal information for as long as we need
        it for a lawful purpose: to perform the contract between you and us
        (enforce our Terms of Use) and to comply with our legal obligations. If
        you no longer want us to use your information that we physically access
        and store, you can request that we erase it.
      </Text>

      <Text py={10} weight={400}>
        However, some data may still be stored for a certain time period (but no
        longer than the storage purpose requires) with a purpose of compliance
        with certain legal obligations (such as taxation, accounting, audit),
        maintenance of safety and data backup settings or prevention of fraud or
        other malicious acts.
      </Text>

      <h4>
        <strong>VIII. EXERCISING YOUR RIGHTS</strong>
      </h4>

      <Text py={10} weight={400}>
        Applicable data protection laws give you certain rights regarding your
        personal information. You have the following rights in relation to your
        personal information that was collected:
      </Text>

      <List>
        <List.Item>
          <strong>Data Access and Portability.&nbsp;</strong>You can request
          copies of your personal information.&nbsp;
        </List.Item>
        <List.Item>
          <strong>Change or Correct Data.&nbsp;</strong>Where you cannot update
          data by yourself, you have the right to ask to correct, change, update
          or rectify your data.
        </List.Item>
        <List.Item>
          <strong>Data Retention and Deletion.</strong>&nbsp;Specific retention
          times can vary based on context of the processing performed. You have
          the right to ask to delete all or some of the personal data that is
          held about you.
        </List.Item>
        <List.Item>
          <strong>Restriction of Processing.&nbsp;</strong>Under certain
          circumstances, you may have the right to limit the ways in which your
          personal information is used.&nbsp;
        </List.Item>
      </List>

      <Text py={10} weight={400}>
        To exercise any of the rights described above, you can contact at&nbsp;
        <Anchor component={Link} to="mailto:support@trendestinsight.com">
          support@trendestinsight.com
        </Anchor>
        . Please bear in mind that we ensure the above mentioned rights only
        with respect to the information that we physically access and store. We
        also would like to draw your attention to the fact that in order to
        process your request we first have to identify you as a user of our
        Website.&nbsp;
      </Text>

      <Text py={10} weight={400}>
        When your information is processed automatically you may object to such
        processing in some circumstances. Where your information is processed
        for direct marketing purposes, you may ask to cease processing your data
        for these direct marketing purposes. In order to exercise this right
        please contact the third party service providers listed in the Section
        IV of this Privacy policy to learn how you can object to processing your
        data. Most of them have clear instructions on their privacy pages,
        functional API or other options.
      </Text>

      <Text py={10} weight={400}>
        If you are located in the European Union, you may address our
        representative when you have questions on privacy issues:
        <br />
        Albina Gulevich Apolskaya, Office: 35, 205 Holland Park Avenue, W11 4XB,
        London, e-mail: user.data.protection @ gmail.com
      </Text>

      <h4>
        <strong>IX. HOW TO OPT OUT</strong>
      </h4>

      <Text py={10} weight={400}>
        You may prevent and/or manage the collection and processing of data
        related to your use of the Website following the instructions provided
        below.
      </Text>

      <Text py={10} weight={400}>
        Google Analytics. If you want to opt-out of being tracked by Google
        Analytics you can install and enable&nbsp;
        <Anchor
          component={Link}
          to="https://tools.google.com/dlpage/gaoptout/?hl=en">
          Google Analytics Opt-out
        </Anchor>
        . Additionally, you can manage cookies and/or delete cookies already set
        by Google Analytics through your browser settings. Further information
        on Google Analytics&rsquo; data practices and relevant instructions can
        be found at&nbsp;
        <Anchor
          component={Link}
          to="https://support.google.com/analytics/answer/6004245">
          answer/6004245
        </Anchor>
        &nbsp;(see section &lsquo;Information for Visitors of Sites and Apps
        Using Google Analytics&rsquo;).
      </Text>

      <Text py={10} weight={400}>
        Yandex.Metrica. As it is indicated in Section IV of the present Privacy
        policy, Yandex.Metrica uses cookies to analyse your activity on the
        Website. You can opt out of using cookie files by choosing the
        corresponding settings in your browser. You can also block the
        collection of information about you by Yandex.Metrica via installing
        Yandex.Metrica opt-out browser add-on available at&nbsp;
        <Anchor
          component={Link}
          to="https://yandex.com/support/metrica/general/opt-out.html">
          opt-out
        </Anchor>
        .
      </Text>

      <Text py={10} weight={400}>
        Bear in mind that if you choose to disable all cookies, this can affect
        the Website functions, some of the features that make the Website more
        efficient may not function.&nbsp;
      </Text>

      <h4>
        <strong>X. SECURITY</strong>
      </h4>

      <Text py={10} weight={400}>
        The security of your personal information is highly important to us. We
        follow generally accepted industry standards to protect the personal
        information provided to us, both during transmission and once we receive
        it.
      </Text>

      <Text py={10} weight={400}>
        We take reasonable and appropriate measures to protect personal
        information from loss, misuse and unauthorized access, disclosure,
        alteration and destruction, taking into account the risks involved in
        the processing and the nature of the personal information.
      </Text>

      <Text py={10} weight={400}>
        We implement appropriate technical and organizational measures, which
        are designed to implement data-protection principles, such as data
        minimization, in an effective manner and to integrate the necessary
        safeguards into the processing.
      </Text>

      <Text py={10} weight={400}>
        Unfortunately, no method of transmission over the Internet, or method of
        electronic storage, is 100% secure. We do our best to protect your
        personal data, nevertheless, we cannot guarantee its absolute security.
        In the event that your personal information is compromised as a breach
        of security, we will promptly notify you in compliance with applicable
        law.
      </Text>

      <Text py={10} weight={400}>
        If you have any questions about the security of our Website, you can
        contact us at&nbsp;
        <Anchor component={Link} to="mailto:support@trendestinsight.com">
          support@trendestinsight.com
        </Anchor>
        &nbsp;or via the &ldquo;Contacts&rdquo; form available on the
        Website.&nbsp;&nbsp;
      </Text>

      <h4>
        <strong>XI. CHILDREN&rsquo;S PRIVACY</strong>
      </h4>

      <Text py={10} weight={400}>
        Our Website is not intended for children under the age of 18. Therefore,
        we do not knowingly collect or solicit any personal information from
        children under 18. No one under the age of 18 may provide any personal
        information to the Website. If you are under 18, do not use or provide
        any information on this Website or through any of its features. Do not
        provide any information about yourself, including your email address. If
        we learn that we have collected personal information from a child under
        the age of 18 without verification of parental consent, we will erase
        that information as quickly as possible. If you believe that we might
        have any information from or about a child under 18, please contact us.
      </Text>

      <h4>
        <strong>XII. CHANGES TO THE PRIVACY POLICY</strong>
      </h4>

      <Text py={10} weight={400}>
        In case we change our Policy, we will post the changes on this page.
        Please check our Website regularly for any changes.
      </Text>

      <h4>
        <strong>XIII. HOW TO CONTACT US</strong>
      </h4>

      <Text py={10} weight={400}>
        If you have any questions about this Privacy policy, please feel free to
        contact us at&nbsp;
        <Anchor component={Link} to="mailto:support@trendestinsight.com">
          support@trendestinsight.com
        </Anchor>
        .
      </Text>
    </Box>
  );
}

export default PrivacyPlain;

const useStyles = createStyles({
  container: {},
});
