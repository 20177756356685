import React from 'react';
import {
  createStyles,
  Box,
  Card,
  Image,
  Group,
  Badge,
  Button,
  Text,
  Stack,
  Center,
} from '@mantine/core';
import { IconChevronDownRight, IconChevronRight } from '@tabler/icons-react';

function AppCard({
  children,
  title = '',
  image = '',
  size,
  url,
  ...otherProps
}) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Card radius={0}>
        <Card.Section>
          <Center>
            <Image
              src={image}
              // width={size ?? 360}
              alt={title}
              withPlaceholder
              className={classes.image}
            />
          </Center>
        </Card.Section>
        <Stack spacing={15} mt={25} align="center">
          <Text weight={900} size={30}>
            {title}
          </Text>

          <Text size="sm" color="dark">
            {children}
          </Text>

          <Button variant="subtle" color="gray" radius="xl">
            <Group spacing={10}>
              <Text weight={400} color="gray" size="sm">
                {url ? 'See More' : 'Coming Soon'}
              </Text>
              <IconChevronRight size={16} />
            </Group>
          </Button>
        </Stack>
      </Card>
    </Box>
  );
}

export default AppCard;

const useStyles = createStyles({
  container: {
    scale: 1,
    WebkitTransition: '0ms',
  },
  image: {
    scale: 1.2,
    WebkitTransition: '250ms',
  },
});
