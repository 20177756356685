import { Global } from '@mantine/core';
import light from '../assets/fonts/SoleilLt.woff2';
import normal from '../assets/fonts/Soleil.woff2';
import semibold from '../assets/fonts/SoleilSb.woff2';
import bold from '../assets/fonts/Soleil-Bold.woff2';
import heavy from '../assets/fonts/SoleilXb.woff2';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Soleil',
            src: `url('${light}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'light',
            fontDisplay: 'block',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Soleil',
            src: `url('${normal}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'normal',
            fontDisplay: 'block',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Soleil',
            src: `url('${semibold}') format("woff2")`,
            fontWeight: 600,
            fontStyle: 'semibold',
            fontDisplay: 'block',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Soleil',
            src: `url('${bold}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'bold',
            fontDisplay: 'block',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Soleil',
            src: `url('${heavy}') format("woff2")`,
            fontWeight: 900,
            fontStyle: 'heavy',
            fontDisplay: 'block',
          },
        },
      ]}
    />
  );
}
