export const themeSizes = {
  maxWidth: 1600,
  minWidth: 375,
};

export const themeBreakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
  xxl: 1536,
};

export const themeColors = {
  // backgound: '#141414',
  black: '#141414',
  white: '#fff',
  dark: '#0f0f0f',
  purple: '#5416b0',
  lightGray: '#f3f3f3',
  lightDark: '#191919',
  darkGray: '#828282',
  anchor: '#0f0f0f',
};
