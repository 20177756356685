import React from 'react';
import { createStyles, Box, UnstyledButton, Text } from '@mantine/core';

function AppLink({ children, ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <UnstyledButton className={classes.smoothClick} {...otherProps}>
        <Text weight={600} size="md" className={classes.text}>
          {children}
        </Text>
      </UnstyledButton>
    </Box>
  );
}

export default AppLink;

const useStyles = createStyles({
  container: {},
  smoothClick: {
    WebkitTransition: '0ms',
    color: 'rgba(255, 255, 255, 0.8)',

    '&:hover': {
      color: 'rgba(255, 255, 255, 1)',
      WebkitTransition: '150ms',
    },

    '&:active': {
      transform: 'translateY(1px)',
      WebkitTransition: '150ms',
    },
  },
  text: {
    whiteSpace: 'nowrap',
    '@media (min-width: 1024px)': {
      fontSize: 12,
    },
    '@media (min-width: 1440px)': {
      fontSize: 14,
    },
    '@media (min-width: 1600px)': {
      fontSize: 16,
    },
  },
});
