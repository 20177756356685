import { Anchor, MantineProvider, Stack, Text } from '@mantine/core';
import AppContainer from './components/AppContainer';
import { CustomFonts } from './config/CustomFonts';
import GlobalStyles from './config/GlobalStyles';
import theme from './config/theme';
import HomePage from './pages/HomePage';
import PrivacyPage from './pages/PrivacyPage';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TermsPage from './pages/TermsPage';

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <GlobalStyles />
      <CustomFonts />
      <AppContainer>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/tos" element={<TermsPage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </AppContainer>
    </MantineProvider>
  );
}

export default App;
