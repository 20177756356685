import {
  createStyles,
  Title,
  Text,
  Center,
  Space,
  Anchor,
  Box,
  Stack,
  List,
} from '@mantine/core';
import AppLogo from '../components/AppLogo';

import { themeColors } from '../config/themeSettings';
import { Link, useParams } from 'react-router-dom';
import PrivacyPlain from './components/PrivacyPlain';

const PrivacyPage = () => {
  const { classes } = useStyles();

  const { language } = useParams();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.flex}>
          <section className={classes.grid}>
            <Anchor component={Link} to="/home">
              <AppLogo color="black" width={200} />
            </Anchor>
            <Space w="100%" />
            <div className={classes.terms}>
              <Anchor component={Link} to="/tos/" className={classes.anchor}>
                Terms & Conditions
              </Anchor>
              <Anchor
                component={Link}
                to="/privacy/"
                className={classes.anchor}>
                Privacy Policy
              </Anchor>
            </div>
          </section>

          <section className={classes.header}>
            <Title order={1} className={classes.title}>
              Privacy Policy
            </Title>
            <Box align="left">
              <PrivacyPlain />
            </Box>
          </section>
        </div>
      </div>

      <Box className={classes.containerDark} pt={50}>
        <div className={classes.flexBottom}>
          <Stack spacing={15} className={classes.flexBottomLogo}>
            <Anchor component={Link} to="/home">
              <AppLogo color="black" width={200} />
            </Anchor>

            <div className={classes.termsBottom}>
              <Anchor component={Link} to="/tos/" className={classes.anchor}>
                Terms & Conditions
              </Anchor>

              <Anchor
                component={Link}
                to="/privacy/"
                className={classes.anchor}>
                Privacy Policy
              </Anchor>
            </div>
          </Stack>
        </div>
      </Box>

      <Box className={classes.containerDarkBottom}>
        <div className={classes.containerGreyBottom}>
          <section className={classes.darksection}>
            <Center pt={12}>
              <Text color="white">
                &copy; {getCurrentYear()} TrendestInsight.com
              </Text>
            </Center>
          </section>
        </div>
      </Box>
    </div>
  );
};

export default PrivacyPage;

const useStyles = createStyles(theme => ({
  root: {
    fontFamily: 'Soleil',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',

    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },

  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  containerText: {
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 900,
    color: themeColors.backgound,

    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'flex-start',
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 425px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },
  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
    fontWeight: 'bold',
  },
  anchorSelected: {
    color: themeColors.anchor,
    fontSize: 14,
    fontWeight: 'normal',
  },

  badges: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 15,
    // gridColumn:

    // maxWidth: 1000,
  },

  title: {
    fontFamily: 'Soleil',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  titleWhite: {
    fontFamily: 'Soleil',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,
  },
  subtitle: {
    fontFamily: 'Soleil',
    fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  redTitle: {
    fontFamily: 'Soleil',
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#EC1D23',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  positionUp: {
    position: 'absolute',
    // bottom: '-20vw',
    // top: -330,
    left: 0,
    right: 0,
    // margin: '0 auto',

    '@media (min-width: 1024px)': {
      bottom: '-250px',
    },
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   top: '-38vw',
    // },
    // '@media (max-width: 767px)': {
    //   top: '-38vw',
    // },
    // '@media (min-width: 1024px)': {
    //   top: '-310px',
    //   // top: '-330px',
    //   // left: '20%',
    //   // right: '20%',
    // },
    // position: 'absolute',
    // top: -100,
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (max-width: 767px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (min-width: 1024px)': {
    //   transform: 'translate(0,-337px)',
    // },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '45vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '450px',
    },
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 20,

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: 50,
      justifyContent: 'space-around',
      // maxWidth: 900,
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Soleil',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    paddingTop: 50,

    // paddingLeft: 30,
    // paddingRight: 30,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 20,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Soleil',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',

    color: themeColors.white,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 2.13,
    },
    '@media (min-width: 1023px)': {
      fontSize: 20,
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
      maxWidth: 350,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 130,
      maxWidth: 350,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      width: 1020,
      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      width: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 50,

    // position: 'relative',
    // '@media (min-width: 768px)': {
    //   height: 110,
    // },
    // overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 100,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 50,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 100,
    },
  },
}));
