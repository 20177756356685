import React from 'react';
import { Box, createStyles } from '@mantine/core';
import { themeColors } from '../config/themeSettings';

function AppContainer({ children, ...other }) {
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.container} {...other}>
        {children}
      </Box>
    </>
  );
}

export default AppContainer;

const useStyles = createStyles({
  container: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    // overflow: 'hidden',
    backgroundColor: themeColors.white,
  },
});
