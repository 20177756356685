import React from 'react';
import { createStyles, Box, Image } from '@mantine/core';

import logo from '../assets/logo.svg';
import logoBlack from '../assets/logoBlack.svg';

function AppLogo({ children, color = 'white', ...otherProps }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Image
        src={color === 'black' ? logoBlack : logo}
        alt="Logo"
        className={classes.image}
        {...otherProps}
      />
    </Box>
  );
}

export default AppLogo;

const useStyles = createStyles({
  container: {},
  image: {
    width: 200,
    height: 64,
  },
});
