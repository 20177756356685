import React from 'react';
import { Global } from '@mantine/core';

export default function GlobalStyles() {
  return (
    <Global
      styles={theme => ({
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      })}
    />
  );
}
