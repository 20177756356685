export default {
  fontFamily: 'Soleil, sans-serif',
  fontDisplay: 'block',
  headings: {
    fontFamily: 'Soleil, sans-serif',
    fontWeight: 500,
  },

  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',

  width: '100%',
  height: '100%',

  focusRing: 'never',

  // Theme is deeply merged with default theme
  colorScheme: 'light',
};
